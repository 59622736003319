import React from 'react';
import styled from 'styled-components';

// Define a styled component for the tooltip
const TooltipWrapper = styled.div`
width: fit-content;
    position: absolute;
    top: -30px;
    right: 130px;
    padding: 5px 10px;
    background-color: #333;
    color: #fff;
    border-radius: 4px;
    font-size: 12px;
    opacity: 0.9;
    @media (max-width: 1028px) {
        right: 30px
    }
`;

// Define the Tooltip component
const Tooltip = ({ children }) => {
    return (
        <TooltipWrapper>
            {children}
        </TooltipWrapper>
    );
};

export default Tooltip;