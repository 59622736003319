import * as React from "react";
import styled from "styled-components";

const menuItems = [
    { label: "About Us", link: "#about" },
    { label: "Ham In Action", link: "#haminaction" },
    { label: "Roadmap", link: "#roadmap" },
    { label: "Buy $hWIF", link: "#buyhwif" },
    { label: "Tokenomics", link: "#tokenomics" },
];

function Header() {
    const scrollToSection = (e, link) => {
        e.preventDefault();
        const section = document.querySelector(link);
        if (section) {
            section.scrollIntoView({ behavior: "smooth" });
        }
    };
    return (
        <HeaderWrapper>
            <Logo src="/img/$hWIF.png" alt="Logo" />
            <Nav>
                {menuItems.map((item, index) => (
                    <NavItem key={index}>
                        <NavLink href={item.link} onClick={(e) => scrollToSection(e, item.link)}>{item.label}</NavLink>
                    </NavItem>
                ))}
            </Nav>
        </HeaderWrapper>
    );
}

const HeaderWrapper = styled.header`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 20px;
  font-size: 24px;
  color: var(--Primary-Color, #00d4fd);
  font-weight: 400;
  @media (max-width: 1028px) {
    padding: 10px 0px;
    display: flex;
    flex-direction: column;
  }
  @media (max-width: 991px) {
    flex-wrap: wrap;
  }
`;

const Logo = styled.img`
  width: 136px;
  max-width: 100%;
  aspect-ratio: 2.22;
  object-fit: contain;
  padding-left: 10px;
  @media (max-width: 1028px) {
    margin-top: 20px;
  }
  @media (max-width: 991px) {
    width: 20%;
  }
  @media (max-width: 468px) {
    width: 35%;
  }
`;

const Nav = styled.nav`
  display: flex;
  gap: 70px;
  margin-left: 150px;
  @media (max-width: 1028px) {
    margin-left: 0px;
    margin-top: 25px;
    gap: 35px;
  }
  @media (max-width: 991px) {
    width: 100%;
    margin-top: 20px;
    gap: 20px;
    margin-left: 0px;
    justify-content: center;
  }
  @media (max-width: 468px) {
    margin-top: 20px;
    gap: 1px 30px;
    display: flex;
    flex-wrap: wrap;
}
`;

const NavItem = styled.li`
  list-style: none;
  @media (max-width: 991px) {
    font-size: 20px;
  }
`;

const NavLink = styled.a`
  font-family: "Peralta", serif;
  text-decoration: none;
  color: #ffffff;
  @media (max-width: 468px) {
    font-size: 14px;
  }
`;

export default Header;