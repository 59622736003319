import React from 'react';
import styled, { keyframes } from 'styled-components';

const bounce = keyframes`
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
`;
const MainDiv = styled.div`
position: absolute;
top: 0px;
@media (max-width: 1028px) {
top: 10%;
}
@media (max-width: 991px) {
    top: 3%;
    right: 30px;
}
@media (max-width: 468px) {
    top: -5%;
    right: 5px;
}
    
`;

const ChatBubble = styled.div`
color: #000000;
  position: relative;
  background-color: #ffffff;
  border-radius: 20px;
  width: 160px;
  padding: 20px;
  margin-left: 50px;
  margin-right: 10px;
  animation: ${bounce} 1s infinite;
  @media (max-width: 468px) {
    border-radius: 12px;
    padding: 15px;
  }
`;

const Arrow = styled.div`
  position: absolute;
  width: 15px;
  height: 15px;
  background-color: #ffffff;
  transform: rotate(45deg);
  bottom: 20px;
  left: -6px;
  @media (max-width: 468px) {
    width: 10px;
    height: 10px;
    left: -5px;
  }
`;

const ChatBubbleWithArrow = () => {
    return (
        <MainDiv>
            <ChatBubble>
                Get $hWIF here now!
                <Arrow />
                {/* <ArrowBorder /> */}
            </ChatBubble>
        </MainDiv>
    );
};

export default ChatBubbleWithArrow;
