import * as React from "react";
import styled from "styled-components";
import { telegramurl, twitterurl, uniswapurl, dextool, linktreeurl } from '../../url';

const data = [
  {
    id: 1,
    image: "/img/uniswap.png",
    url: uniswapurl
  },
  {
    id: 1,
    image: "/img/twitter.png",
    url: twitterurl
  },
  {
    id: 2,
    image: "/img/telegram.png",
    url: telegramurl
  },
  {
    id: 3,
    image: "/img/Dextool.png",
    url: dextool
  },
  {
    id: 3,
    image: "/img/linktree.png",
    url: linktreeurl
  }
];

function MyComponent() {

  const handleSocialLinkClick = (url) => {
    window.open(url, '_blank'); // Open the URL in a new tab
  };

  return (
    <MainContainer>
      <Title>Hammy's Hat Hits and Guitar Grit!</Title>
      <FooterContainer>
        <LineCoin>
          {/* <Line1></Line1> */}
          <ImageCoin>
            <Image1 src="/img/$hWIF.png" alt="coin image" />
          </ImageCoin>
          <Line1></Line1>
        </LineCoin>
        <SocilMediaSection>
          <Copyright></Copyright>
          <SocialIcons>
            {data.map((item) => (
              item.url && (
                <Image onClick={() => handleSocialLinkClick(item.url)} src={item.image} alt="footer image" />
              )
            ))}
          </SocialIcons>
        </SocilMediaSection>
      </FooterContainer>
    </MainContainer>
  );
}

const MainContainer = styled.div`
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  color: var(--Font-color, #fff);
  font-weight: 400;
  padding: 0px;
  width: 100%;
  @media (max-width: 1028px) {
    padding: 0px;
  }
  @media (max-width: 468px) {
    margin-top: 50px;
}
`;

const Title = styled.h1`
  align-self: center;
  font: 60px "Zeyada", cursive;
  @media (max-width: 1028px) {
    font: 50px "Zeyada", cursive;
  }
  @media (max-width: 991px) {
    max-width: 100%;
    font-size: 40px;
  }
  @media (max-width: 468px) {
    font-size: 26px;
    margin: 0px
}
`;

const FooterContainer = styled.footer`
  display: flex;
  width: 100%;
  gap: 20px;
  font-size: 18px;
  align-items: center;
  flex-direction: column;
  margin-top: 50px;
  @media (max-width: 991px) {
    max-width: 100%;
    // flex-wrap: wrap;
    margin-top: 40px;
  }
  @media (max-width: 468px) {
    margin-top: 0px;
    gap: 0px;
  }
`;

const LineCoin = styled.div`
display: flex;
width: 100%;
align-items: center;
gap: 20px;
font-size: 18px;
flex-direction: column;
@media (max-width: 1028px) {
    gap: 0px;
}
@media (max-width: 991px) {
  max-width: 100%;
//   flex-wrap: wrap;
  margin-top: 0px;
}
`;

const Copyright = styled.p`
  font-family: Istok Web, sans-serif;
  width: 20%;
  text-align: start;
margin: 0px 20px 30px;
  @media (max-width: 468px) {
    font-size: 12px;
    margin: 20px 20px 20px;
  }
`;
const SocilMediaSection = styled.div`
width: 100%;
display: flex;
align-items: center;
justify-content: space-between;
@media (max-width: 1028px) {
    margin-bottom: 20px!important;
}
`;

const SocialIcons = styled.div`
display: flex;
align-items: center;
margin: 0px 20px 30px;
@media (max-width: 468px) {
    margin: 20px 20px 20px;
}
`;

const ImageCoin = styled.div`
width: 15%;
display: flex;
justify-content: center;
margin-bottom: 50px;
@media (max-width: 468px) {
    width: 20%;
    margin-bottom: 20px;
}
`;

const Line1 = styled.div`
width: 98%;
height: 1.5px;
background: #ffffff;
@media (max-width: 1028px) {
    margin: 0px 20px!important;
}
@media (max-width: 468px) {
  width: 94%;
}
`;

const Image1 = styled.img`
//   aspect-ratio: ${(props) => (props.src === "https://cdn.builder.io/api/v1/image/assets/TEMP/876d7bb4b26de8210fc9ec4cf351f4ea72186fbac72270e8d722acb60fa3f61d?apiKey=4ea074c2172a4f308a4b8ec9272610d2&" ? "4.17" : "0.99")};
  object-fit: auto;
  object-position: center;
//   width: ${(props) => (props.src === "https://cdn.builder.io/api/v1/image/assets/TEMP/876d7bb4b26de8210fc9ec4cf351f4ea72186fbac72270e8d722acb60fa3f61d?apiKey=4ea074c2172a4f308a4b8ec9272610d2&" ? "92px" : "106px")};
  align-self: stretch;
  max-width: 100%;
  width: 100%;
  margin-top: ${(props) => (props.src === "https://cdn.builder.io/api/v1/image/assets/TEMP/876d7bb4b26de8210fc9ec4cf351f4ea72186fbac72270e8d722acb60fa3f61d?apiKey=4ea074c2172a4f308a4b8ec9272610d2&" ? "72px" : "0")};

  @media (max-width: 991px) {
    margin-top: ${(props) => (props.src === "https://cdn.builder.io/api/v1/image/assets/TEMP/876d7bb4b26de8210fc9ec4cf351f4ea72186fbac72270e8d722acb60fa3f61d?apiKey=4ea074c2172a4f308a4b8ec9272610d2&" ? "40px" : "0")};
  }
`;

const Image = styled.img`
  height: 20px;
  width: 20px;
  margin-left: 20px;
  max-width: 100%;
  margin-top: ${(props) => (props.src === "https://cdn.builder.io/api/v1/image/assets/TEMP/876d7bb4b26de8210fc9ec4cf351f4ea72186fbac72270e8d722acb60fa3f61d?apiKey=4ea074c2172a4f308a4b8ec9272610d2&" ? "72px" : "0")};
  cursor: pointer;
  @media (max-width: 991px) {
    margin-top: ${(props) => (props.src === "https://cdn.builder.io/api/v1/image/assets/TEMP/876d7bb4b26de8210fc9ec4cf351f4ea72186fbac72270e8d722acb60fa3f61d?apiKey=4ea074c2172a4f308a4b8ec9272610d2&" ? "40px" : "0")};
  }
  &:first-child {
    width: 30px;
    height: 30px;
  }
`;

export default MyComponent;