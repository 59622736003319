import React, { useState } from "react";
import styled from "styled-components";

const roadmapData = [
    {
        image: "/img/Group1.png",
        phase: "Hambino",
        description: `Lorem Ipsum lorem ipsum lorem ipsum
Lorem Ipsum lorem ipsum lorem ipsum
Lorem Ipsum lorem ipsum lorem ipsum
Lorem Ipsum lorem ipsum lorem ipsum`,
    },
    {
        image: "/img/Group2.png",
        phase: "Groovester",
        description: `Lorem Ipsum lorem ipsum lorem ipsum
Lorem Ipsum lorem ipsum lorem ipsum
Lorem Ipsum lorem ipsum lorem ipsum
Lorem Ipsum lorem ipsum lorem ipsum`,
    },
    {
        image: "/img/Group3.png",
        phase: "Chompers",
        description: `Lorem Ipsum lorem ipsum lorem ipsum
Lorem Ipsum lorem ipsum lorem ipsum
Lorem Ipsum lorem ipsum lorem ipsum
Lorem Ipsum lorem ipsum lorem ipsum`,
    },
    {
        image: "/img/Group4.png",
        phase: "Fretmaster",
        description: `Lorem Ipsum lorem ipsum lorem ipsum
Lorem Ipsum lorem ipsum lorem ipsum
Lorem Ipsum lorem ipsum lorem ipsum
Lorem Ipsum lorem ipsum lorem ipsum`,
    },
];


function MemeExplanation() {
    const [isPlaying, setIsPlaying] = useState(false);
    const [shakingIndex, setShakingIndex] = useState(null);

    const audios = [
        "/audio/1.mp3",
        "/audio/2.mp3",
        "/audio/3.mp3",
        "/audio/4.mp3",
        "/audio/5.mp3",
        "/audio/6.mp3",
        "/audio/7.mp3",
        "/audio/8.mp3",
        "/audio/9.mp3",
        "/audio/10.mp3",
        "/audio/11.mp3",
        "/audio/12.mp3",
    ];

    const handleClick = (index) => {
        if (!isPlaying) {
            const randomIndex = Math.floor(Math.random() * audios.length);
            const audio = new Audio(audios[randomIndex]);

            audio.addEventListener('ended', () => {
                setIsPlaying(false); // Reset isPlaying state after audio ends
                setShakingIndex(null); // Reset shakingIndex after audio ends
            });

            audio.play();
            setIsPlaying(true);
            setShakingIndex(index); // Set shakingIndex to the index of the clicked image
        }
    };

    return (
        <ExplanationSection id="haminaction">
            <Heading>Ham In Action</Heading>
            <Description>
                In a world where cryptocurrencies are as unpredictable as a hamster's mood swings, one coin stands out: hWIF (Hamster Wearing Hat and Playing Guitar) Coin! . The coin's creators, in a eureka moment, saw the internet's obsession with Hammy and thought, "Why not make a coin out of this funky phenomenon?" And so, $hWIF was born—a token for those who believe in rocking out while investing.
                Forget 'rat' races, we're in the 'hamster' wheel of crypto now!
            </Description>
            {/* <MemeImage src="/img/Coin.png" /> */}
            <RoadmapPhases>
                {roadmapData.map((phase, index) => (
                    <RoadmapPhase key={index}>
                        <RoadmapPhaseImage
                            className={shakingIndex === index && isPlaying ? "shake" : ""}
                            onClick={() => handleClick(index)}
                            src={phase.image}
                            alt={`Phase ${index + 1} illustration`}
                        />
                        <RoadmapPhaseTitle>{phase.phase}</RoadmapPhaseTitle>
                        {/* <RoadmapPhaseDescription>
                            {phase.description}
                        </RoadmapPhaseDescription> */}
                    </RoadmapPhase>
                ))}
            </RoadmapPhases>
        </ExplanationSection>
    );
}

const ExplanationSection = styled.section`
margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--Font-color, #fff);
  font-weight: 400;
  padding: 0 20px;
  @media (max-width: 1028px) {
    padding: 0px;
  }
  @media (max-width: 468px) {
    margin-top: 70px;
  }
`;

const Heading = styled.h2`
  font: 60px "Zeyada", cursive;
  margin-bottom: 0px;
  @media (max-width: 991px) {
    font-size: 40px;
  }
  @media (max-width: 468px) {
    margin: 0px;
  }
`;

const Description = styled.p`
  text-align: center;
  width: 60%;
  font: 18px Istok Web, sans-serif;
  line-height: 25px;
  @media (max-width: 1028px) {
    width: 75%;
  }
  @media (max-width: 991px) {
    margin-top: 40px;
  }
  @media (max-width: 468px) {
    width: 85%;
    text-align: justify;
    margin-top: 20px;
  }
`;

const MemeImage = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  width: 432px;
  margin-top: 37px;
  max-width: 100%;
  @media (max-width: 1028px) {
    width: 300px;
  }
  @media (max-width: 468px) {
    width: 200px;
    margin-top: 30px;
  }
`;

const RoadmapPhases = styled.div`
  margin-top: 69px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center;
  @media (max-width: 1028px) {
    margin-top: 10px;
  }
  @media (max-width: 991px) {
    align-items: stretch;
    gap: 40px;
    max-width: 100%;
    margin-top: 40px;
  }
`;

const RoadmapPhase = styled.div`
  display: flex;
  flex-direction: column;
//   overflow: hidden;
  position: relative;
  min-height: 307px;
//   flex-grow: 1;
  width: 22%;
  align-items: start;
  color: #fff;
  font-weight: 400;
  border-radius: 15px;
//   padding: 50px 80px 50px 29px;
//   width: calc(50% - 10px);
@media (max-width: 1028px) {
    width: 20%;
  }
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
    padding: 0 20px;
    width: 40%;
  }
  @media (max-width: 468px) {
    margin-top: 0px;
    width: 55%;
  }
`;

const RoadmapPhaseImage = styled.img`
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: contain;
  object-position: center;
`;

const RoadmapPhaseTitle = styled.h3`
  position: absolute;
  margin: 0px;
  bottom: -70px;
  font: 40px Zeyada, sans-serif;
  left: 50%; 
  transform: translateX(-50%);
  @media (max-width: 1028px) {
    bottom: -15px;
  }
  @media (max-width: 991px) {
    font-size: 40px;
  }
`;

const RoadmapPhaseDescription = styled.p`
  position: relative;
  margin-top: 39px;
  font: 20px/37px K2D, sans-serif;
`;

export default MemeExplanation;