import React, { useState } from "react";
import styled, { keyframes, css } from "styled-components";

const dropCoin = keyframes`
  0% {
    transform: translateX(-5px);
  }
  25% {
    transform: translateX(5px);
  }
  50% {
    transform: translateX(-5px);
  }
  75% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
  }
`;

const pulseAnimation = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`;

function MemeExplanation() {

    const MemeImage = styled.img`
    aspect-ratio: 1;
  object-fit: contain;
  width: 432px;
  margin-top: 37px;
  max-width: 100%;
  @media (max-width: 1028px) {
    width: 300px;
  }
  @media (max-width: 468px) {
    width: 200px;
    margin-top: 30px;
  }
    /* Apply drop animation */
    animation: ${({ isPlaying }) => (isPlaying ? css`${dropCoin} 1s ease forwards` : 'none')};
  `;

    const Tooltip = styled.div`
  position: absolute;
  bottom: -20px;
  left: 48%;
  transform: translateX(-50%);
  font-size: 14px;
  color: #fff;
  animation: ${pulseAnimation} 1s ease infinite;
`;

    const [isPlaying, setIsPlaying] = useState(false);

    const handleClick = () => {
        if (!isPlaying) {
            const audio = new Audio('/audio/coin1.mp3'); // Replace '/path/to/audio.mp3' with your audio file
            audio.play();
            setIsPlaying(true);

            audio.addEventListener('ended', () => {
                setIsPlaying(false); // Reset isPlaying state after audio ends
            });
        }
    };
    
    return (
        <ExplanationSection id="about">
            <Heading>What is $hWIF coin?</Heading>
            <Description>
            Introducing $hWIF (Hamster Wearing Hat) coin! 🐹🎩🎸 Invest today and join the wild world of crypto with a side of hamster hilarity. Our coin's value swings more than a hamster on a wheel! 🔄 Don't worry, our team is as nimble as a hamster on caffeine. With $hWIF, you'll laugh all the way to the bank—or at least the pet store! Grab your hat and strum along to the meme-worthy rise of $hWIF. It's crypto like you've never seen before!
            </Description>
            <MemeImage src="/img/Coin.png" isPlaying={isPlaying} onClick={handleClick} title="Click me to play" />
            <Tooltip>Tap on coin</Tooltip>
        </ExplanationSection>
    );
}

const ExplanationSection = styled.section`
margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--Font-color, #fff);
  font-weight: 400;
  padding: 0 20px;
  position: relative;
  @media (max-width: 1028px) {
    padding: 0px;
  }
  @media (max-width: 468px) {
    margin-top: 0px;
  }
`;

const Heading = styled.h2`
  font: 60px "Zeyada", cursive;
  margin-bottom: 0px;
  @media (max-width: 991px) {
    font-size: 40px;
  }
  @media (max-width: 468px) {
    margin: 0px;
  }
`;

const Description = styled.p`
  text-align: center;
  width: 60%;
  font: 18px Istok Web, sans-serif;
  line-height: 25px;
  @media (max-width: 1028px) {
    width: 75%;
  }
  @media (max-width: 991px) {
    margin-top: 40px;
  }
  @media (max-width: 468px) {
    width: 85%;
    text-align: justify;
    margin-top: 20px;
  }
`;

const MemeImage = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  width: 432px;
  margin-top: 37px;
  max-width: 100%;
  @media (max-width: 1028px) {
    width: 300px;
  }
  @media (max-width: 468px) {
    width: 200px;
    margin-top: 30px;
  }
`;

export default MemeExplanation;