import React, { useState } from "react";
import styled, { keyframes, css } from "styled-components";
import CryptoAddress from './WalletAddressButton';
import { smartcontractAddress } from '../../url';

const bounceAnimation = keyframes`
  0%{
    transform: translateY(0px) scale(1);
  }
  50% {
    transform: translateY(70px) scale(1);
  }
  100% {
    transform: translateY(0px) scale(1);
  }
`;

function MyComponent() {
  const [animate, setAnimate] = useState(false);

  const handleClick = () => {
    setAnimate(true);
    setTimeout(() => {
      setAnimate(false);
    }, 2000); // Duration of the animation
  };

  const handleExploreClick = () => {
    const tokenSection = document.getElementById('whatiskwif');
    if (tokenSection) {
      tokenSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <MainSection>
      <ContentWrapper>
        <ImageColumn>
          <HeroImage src="/img/hamster1.png" alt="Hero Image" />
        </ImageColumn>
        <ContentColumn>
          <ContentContainer>
            <Title>
              <TitleSpan>Welcome to $hWIF</TitleSpan>   <br />
              Meme World
            </Title>
            {/* <ExploreButton onClick={handleExploreClick}>Explore Now</ExploreButton> */}
            {smartcontractAddress && (
              <CryptoAddress />
            )}
          </ContentContainer>
        </ContentColumn>
      </ContentWrapper>
    </MainSection>
  );
}

const MainSection = styled.section`
  margin-top: 70px;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0;
  }
  @media (max-width: 468px) {
    min-height: 75vh;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
  @media (max-width: 991px) {
    flex-direction: column;
    gap: 0;
    align-items: center;
  }
  @media (max-width: 468px) {
  align-items: center;
  }
`;

const ContentColumn = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 50%;
  margin-left: 0;
  @media (max-width: 1028px) {
    width: 60%;
  }
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;
  font-weight: 400;
  margin: auto 0;
  padding: 0 20px 0 80px;
  @media (max-width: 1028px) {
    padding: 0 20px 0 50px;
  }
  @media (max-width: 991px) {
    padding: 0 20px 0 20px;
    max-width: 100%;
    margin-top: 0px;
  }
  @media (max-width: 468px) {
    padding: 0 20px 0 20px;
    align-items: center;
  }
`;

const Title = styled.h1`
  color: var(--Font-color, #fff);
  font: 70px "Zeyada", cursive;
  text-align: start;
  @media (max-width: 1028px) {
    margin-bottom: 0px;
  }
  @media (max-width: 991px) {
    max-width: 100%;
    font-size: 40px; 
    text-align: center;
  }
  @media (max-width: 468px) {
    text-align: center;
  }

`;

const TitleSpan = styled.span`
  color: #00D4FD;
`;

const ExploreButton = styled.button`
  justify-content: center;
  border-radius: 10px;
  background-color: var(--Button-color, #fff);
  align-self: start;
  margin-top: 0px;
  margin-bottom: 50px;
  color: #000;
  padding: 10px;
  font: 24px "Zeyada", cursive;
  @media (max-width: 991px) {
    align-self: center!important;
  }
  @media (max-width: 468px) {
    font: 20px "Zeyada", cursive;
    margin-bottom: 0px;
    align-self: center!important;
  }
`;

const ImageColumn = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 50%;
  margin-left: 20px;
  align-items: flex-end;
  @media (max-width: 1028px) {
    width: 40%;
  }
  @media (max-width: 991px) {
    width: 90%;
    margin-left: 0px;
    align-items: center;
  }
  @media (max-width: 468px) {
    margin-left: 0px;
    width: 80%;
  }
`;

const HeroImage = styled.img`
//   aspect-ratio: 0.85;
  object-fit: contain;
  object-position: center;
  width: 80%;
//   flex-grow: 1;
//   transform: translate(0px, 0px) scale(1);
  z-index: 9999; // Adjust the z-index value as needed
  animation: ${({ animate }) => animate ? css`${bounceAnimation} 2s ease-in-out infinite alternate` : 'none'};
  @media (max-width: 1028px) {
    object-fit: contain;
    width: 90%;
  }
  @media (max-width: 991px) {
    max-width: 100%;
    width: 70%;
    margin-top: 0px;
  }
`;

export default MyComponent;