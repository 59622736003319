import * as React from "react";
import styled from "styled-components";
import { uniswapurl } from "../../url";
import ChatBubbleWithArrow from "./ChatBubble";

function HowToBuyOnUniswap() {
  return (
    <Container id="buyhwif">
      <Title>How to buy on Uniswap?</Title>
      <Description>
        To get your hands on some hWIF via Uniswap, let's dive into the fun! Make sure you've got Ethereum (ETH) stored in MetaMask, Trust Wallet, or your preferred digital wallet. Head to Uniswap, connect your wallet, and navigate to the trade interface. If hWIF isn't showing up, manually add the contract address like you're on a mission to find buried treasure. Decide how much ETH you want to swap for hWIF, watch out for those sneaky gas fees, and hit confirm like you're hitting a high note on a tiny guitar. Sit tight, imagine the excitement of a hamster rocking out, and voila! You've just scored yourself some hWIF. Remember, don't go nuts—research before you rock and roll with your crypto!
      </Description>
      <ContentWrapper>
        <ImageWrapper>
          <Image loading="lazy" src="/img/hamster2.png" alt="Web view screenshot" />
          <ChatBubbleWithArrow />
        </ImageWrapper>
        <WebViewWrapper>
          <IframWrapper>
            <iframe
              width="100%"
              height="660px"
              src={uniswapurl}
            ></iframe>
          </IframWrapper>
          {/* <WebViewText>WEB VIEW</WebViewText> */}
        </WebViewWrapper>
      </ContentWrapper>
    </Container>
  );
}

const Container = styled.div`
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
  @media (max-width: 1028px) {
    padding: 0px;
  }
`;

const Title = styled.h1`
  color: var(--Font-color, #fff);
  font: 400 60px "Zeyada", cursive;
  margin-bottom: 0px;
  @media (max-width: 991px) {
    max-width: 100%;
    font-size: 40px;
  }
  @media (max-width: 468px) {
    margin: 0px
  }
`;

const Description = styled.p`
  color: var(--Font-color, #fff);
  text-align: center;
  margin-top: 27px;
  width: 60%;
  font: 400 18px Istok Web, sans-serif;
  line-height: 25px;
  @media (max-width: 1028px) {
    width: 75%;
  }
  @media (max-width: 991px) {
    max-width: 100%;
  }
  @media (max-width: 468px) {
    width: 85%;
    text-align: justify;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
//   gap: 20px;
  align-self: stretch;
  margin-top: 44px;
  width: 100%;
  justify-content: center;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0;
    margin-top: 40px;
  }
`;

const WebViewWrapper = styled.div`
align-items: center;
  display: flex;
  width: 55%;
  margin-left: 0;
  justify-content: flex-start;
  @media (max-width: 1028px) {
  // width: 100%;
  justify-content: center;
  }
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const IframWrapper = styled.div`
align-items: center;
  display: flex;
  width: 660px;
  @media (max-width: 1028px) {
width: 660px;
  }
  @media (max-width: 468px) {
    width: 88%;
      }

`;

const WebViewText = styled.div`
  border-radius: 10px;
  background-color: #86afc6;
  align-self: stretch;
  align-items: center;
  color: #000;
  justify-content: center;
  width: 100%;
  margin: auto 0;
  padding: 235px 60px 80px;
  font: 400 60px Spicy Soup, sans-serif;
  
  @media (max-width: 991px) {
    font-size: 40px;
    max-width: 100%;
    margin-top: 40px;
    padding: 40px 20px 0;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  display: flex;
//   flex-direction: column;
  line-height: normal;
  width: 45%;
  margin-left: 20px;
  justify-content: flex-end;
  align-items: center;
  @media (max-width: 1028px) {
    width: 40%;
    margin-left: 0px;
    // display: none;
   }
  @media (max-width: 991px) {
    justify-content: center;
    width: 100%;
  }
`;

const Image = styled.img`
//   aspect-ratio: 0.85;
  object-fit: cover;
  object-position: center;
  width: 85%;
//   flex-grow: 1;
  @media (max-width: 1028px) {
    object-fit: contain;
    width: 80%;
  }
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 30px;
  }
`;

export default HowToBuyOnUniswap;