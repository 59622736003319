import * as React from "react";
import './App.css'
import HeaderSection from './components/HeaderSection/HeaderSection'
import HeroSection from "./components/HeroSection/HeroSection";
import HamWifHat from "./components/HamWifHat/HamWifHat";
import MemeSection from "./components/MemeSection/MemeSection";
import RoadmapSection from "./components/RoadmapSection/RoadmapSection";
import Tokenomics from "./components/TokenomicsSection/TokenomicsSection";
import BuyTokenSection from "./components/BuyTokenSection/BuyTokenSection";
import FooterSection from "./components/FooterSection/FooterSection";
import GuitarAnimation from './components/GuitarSection/Guitar';
import FunnyImages from './components/FunnyImageSection/funy'

function App() {

  return (
    <>
      <HeaderSection />
      <HeroSection />
      <HamWifHat />
      <MemeSection />
      <FunnyImages />
      <RoadmapSection />
      <BuyTokenSection />
      <Tokenomics />
      <FooterSection />
      {/* <GuitarAnimation /> */}
    </>
  )
}

export default App