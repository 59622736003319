import React, { useRef, useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';

const images = [
  { src: "/img/Designer1.png", tagline: "Vroom Vroom, Hamster Zoom!" },
  { src: "/img/Designer2.png", tagline: "Bat, Ball, and a Hamster's Call!" },
  { src: "/img/Designer3.png", tagline: "Shell Yeah! Hamster's Beach Adventure Begins!" },
  { src: "/img/Designer4.png", tagline: "The Hamster Rocket: Fluffy Fuel for Flight!" },
  { src: "/img/Designer5.png", tagline: "Dust, Grit, and Hamster Grit: The Desert Saga!" },
  { src: "/img/Designer6.png", tagline: "Selfie Stick? Nah, Just Fluffy Paws! But Watch Out for the Hamster Photobomb!" },
  { src: "/img/Designer1.png", tagline: "Vroom Vroom, Hamster Zoom!" },
  { src: "/img/Designer2.png", tagline: "Bat, Ball, and a Hamster's Call!" },
  { src: "/img/Designer3.png", tagline: "Shell Yeah! Hamster's Beach Adventure Begins!" },
  { src: "/img/Designer4.png", tagline: "The Hamster Rocket: Fluffy Fuel for Flight!" },
  { src: "/img/Designer5.png", tagline: "Dust, Grit, and Hamster Grit: The Desert Saga!" },
  { src: "/img/Designer6.png", tagline: "Selfie Stick? Nah, Just Fluffy Paws! But Watch Out for the Hamster Photobomb!" },
  { src: "/img/Designer1.png", tagline: "Vroom Vroom, Hamster Zoom!" },
  { src: "/img/Designer2.png", tagline: "Bat, Ball, and a Hamster's Call!" },
  { src: "/img/Designer3.png", tagline: "Shell Yeah! Hamster's Beach Adventure Begins!" },
  { src: "/img/Designer4.png", tagline: "The Hamster Rocket: Fluffy Fuel for Flight!" },
  { src: "/img/Designer5.png", tagline: "Dust, Grit, and Hamster Grit: The Desert Saga!" },
  { src: "/img/Designer6.png", tagline: "Selfie Stick? Nah, Just Fluffy Paws! But Watch Out for the Hamster Photobomb!" },
];

const SliderContainer = styled.section`
  margin-top: 200px;
  display: flex;
  overflow: hidden;
  width: 100%;
  @media (max-width: 468px) {
  margin-top: 100px;
  }

`;

const slideAnimation = keyframes`
  0% { transform: translateX(0); }
  100% { transform: translateX(-${(images.length * (200 + 20))}px); }
`;

const SliderWrapper = styled.div`
  display: flex;
  animation: ${slideAnimation} 20s linear infinite;
  &:hover {
    animation-play-state: paused;
  }
`;

const Image = styled.img`
  width: 400px;
  height: 400px;
  border-radius: 15px;
  margin-right: 20px;
  @media (max-width: 468px) {
    width: 300px;
    height: 300px;
    margin-right: 10px;
  }
`;

const Slide = styled.div`
  width: 400px;
  margin-right: 20px;
  flex-shrink: 0;
  border-radius: 15px;
  overflow: hidden;
  position: relative;
  &:last-child {
    margin-right: 0;
  }
  &:hover .tagline {
    opacity: 1;
    transform: translateY(0);
  }
  @media (max-width: 468px) {
    width: 300px;
  }
`;

const Tagline = styled.div`
  position: absolute;
  bottom: 10px;
  left: 0;
  width: 90%;
  // height: 100%;
  justify-content: center;
  display: flex;
  align-item: center;
  text-align: center;
  color: white;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 10px 20px;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.3s, transform 0.3s;
`;

const duplicatedImages = [...images, ...images];

const Slider = () => {
  const sliderRef = useRef(null);
  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    const sliderWidth = sliderRef.current.offsetWidth;
    const totalWidth = duplicatedImages.length * (300 + 20);
    const animationDuration = totalWidth / sliderWidth * 5;

    sliderRef.current.style.animationDuration = `${animationDuration}s`;
  }, []);

  const handleHover = () => {
    setHovered(!hovered);
    sliderRef.current.style.animationPlayState = hovered ? "paused" : "running";
  };

  return (
    <SliderContainer ref={sliderRef}>
      <SliderWrapper>
        {duplicatedImages.map((image, index) => (
          <Slide key={index} onMouseEnter={handleHover} onMouseLeave={handleHover}>
            <Image onMouseEnter={handleHover} onMouseLeave={handleHover} key={index} src={image.src} alt={`Slide ${index + 1}`} />
            <Tagline className="tagline">{image.tagline}</Tagline>
          </Slide>
        ))}
      </SliderWrapper>
    </SliderContainer>
  );
};

export default Slider;