import React, { useState } from 'react';

const Guitar = () => {
    const audios = [
        "/audio/1.mp3",
        "/audio/2.mp3",
        "/audio/3.mp3",
        "/audio/4.mp3",
        "/audio/5.mp3",
        "/audio/6.mp3",
        "/audio/7.mp3",
        "/audio/8.mp3",
        // Add all your audio sources here
    ];

    const [shake, setShake] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);

    const handleClick = () => {
        if (!isPlaying) {
            const randomIndex = Math.floor(Math.random() * audios.length);
            const audio = new Audio(audios[randomIndex]);

            audio.addEventListener('ended', () => {
                setIsPlaying(false); // Reset isPlaying state after audio ends
            });

            audio.play();
            setIsPlaying(true);
        }
    };

    return (
        <div className='imgDiv'>
            <img
                src="/img/hamster.png"
                alt="Guitar"
                onClick={handleClick}
                className={isPlaying ? "shake" : ""}
            />
        </div>
    );
};

export default Guitar;