import * as React from "react";
import styled from "styled-components";

const roadmapData = [
    {
        image: "https://cdn.builder.io/api/v1/image/assets/TEMP/fc7aa5ae80cabcedbea83b62495d782d59d9c6ce7d00d3b50191b5bcb397cd83?apiKey=4ea074c2172a4f308a4b8ec9272610d2&",
        phase: "Hamster Jam Session 🎸",
        description: `Launch $hWIF token with a bang!
        Get $hWIF listed on DEXs faster than a hamster with a guitar.
        Reach 1,000+ holders quicker than a hamster's wheel spins.
        Witness the $hWIF community takeover—hamster style!`,
    },
    {
        image: "https://cdn.builder.io/api/v1/image/assets/TEMP/df9d22c2d6d09ce9130547e9c4967dcf57f4be3fd5288199661159f4022efd10?apiKey=4ea074c2172a4f308a4b8ec9272610d2&",
        phase: "Rockstar Status 🌟",
        description: `Score CEX listings for $hWIF, making it mainstream.
        $hWIF normie takeover—hamster memes go viral!
        Form strategic partnerships faster than a hamster can shred on guitar.
        Rock on with 10,000+ holders grooving to $hWIF beats.`,
    },
    {
        image: "https://cdn.builder.io/api/v1/image/assets/TEMP/2c536aee6b5d61f7c71131b46ea29c27f22e3cf0b0af66a66ef10fcfd5ce0348?apiKey=4ea074c2172a4f308a4b8ec9272610d2&",
        phase: "Hamster Celeb Status 🌟🎩",
        description: `Secure Tier 1 exchange listings, putting $hWIF in the limelight.
        Launch the official $hWIF Hamster Merch—hat and guitar included!
        Hit the stage with 50,000+ holders, cheering on our hamster hero.`,
    },
    {
        image: "https://cdn.builder.io/api/v1/image/assets/TEMP/fef8c91cbbcaeb8da0505249338c1c974aabdd077fc325f2f598c5964030214d?apiKey=4ea074c2172a4f308a4b8ec9272610d2&",
        phase: "Hamster Domination 🚀🌎",
        description: `Unleash $hWIF's legendary NFT collection—hamster-themed art galore!
        Celebrate 100,000+ holders—hamster empire in full swing.
        Prepare for $hWIF world domination, one hat-wearing hamster at a time.`,
    },
];

function MyComponent() {
    return (
        <RoadmapSection id='roadmap'>
            <RoadmapTitle>Roadmap</RoadmapTitle>
            {/* <RoadmapDescription>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy text ever
                since the 1500s, when an unknown printer took a galley of type and
                scrambled it to make a type specimen book. It has survived not only five
                centuries, but also the leap into electronic typesetting, remaining
                essentially unchanged. It was popularised in the 1960s with the release
                of Letraset sheets containing Lorem Ipsum passages, and more recently
                with desktop publishing software like Aldus PageMaker including versions
                of Lorem Ipsum.
            </RoadmapDescription> */}
            <RoadmapPhases>
                {roadmapData.map((phase, index) => (
                    <RoadmapPhase key={index}>
                        <RoadmapPhaseImage
                            src={phase.image}
                            alt={`Phase ${index + 1} illustration`}
                        />
                        <RoadmapPhaseTitle>{phase.phase}</RoadmapPhaseTitle>
                        <RoadmapPhaseDescription>
                            <ul>
                                {phase.description.split('\n').map((point, pointIndex) => (
                                    <li key={pointIndex}>{point}</li>
                                ))}
                            </ul>
                            {/* {phase.description} */}
                        </RoadmapPhaseDescription>
                    </RoadmapPhase>
                ))}
            </RoadmapPhases>
        </RoadmapSection>
    );
}

const RoadmapSection = styled.section`
margin-top: 100px;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  @media (max-width: 1028px) {
    margin-top: 70px;
  }
`;

const RoadmapTitle = styled.h2`
margin-bottom: 0px;
  color: #fff;
  align-self: center;
  font: 400 60px Zeyada, sans-serif;
  @media (max-width: 991px) {
    font-size: 40px;
  }
`;

const RoadmapDescription = styled.p`
  text-align: start;
  align-self: center;
  color: var(--Font-color, #fff);
  margin-top: 47px;
  width: 60%;
  font: 400 18px K2D, sans-serif;
  @media (max-width: 1028px) {
    width: 75%;
  }
  @media (max-width: 991px) {
    margin-top: 40px;
  }
  @media (max-width: 468px) {
    width: 85%;
    text-align: justify;
  }
`;

const RoadmapPhases = styled.div`
  margin-top: 69px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
    max-width: 100%;
    margin-top: 40px;
    align-content: center;
  }
`;

const RoadmapPhase = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  min-height: 307px;
//   flex-grow: 1;
  width: 35%;
  align-items: start;
  color: #fff;
  font-weight: 400;
  border-radius: 15px;
  padding: 50px 30px 50px 30px;
//   width: calc(50% - 10px);
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
    padding: 0 20px;
    width: 80%;
  }
  @media (max-width: 991px) {
    padding: 20px 20px 0px;
  }
`;

const RoadmapPhaseImage = styled.img`
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;

`;

const RoadmapPhaseTitle = styled.h3`
  position: relative;
  margin: 0px;
  font: 50px Zeyada, sans-serif;
  @media (max-width: 991px) {
    font-size: 40px;
  }
`;

const RoadmapPhaseDescription = styled.p`
  position: relative;
//   margin-top: 39px;
  font: 20px/37px K2D, sans-serif;
  & ul {
    list-style-type: disc; /* Change bullet style */
    margin-left: 0px; /* Add left margin */
    padding-left: 20px
  }
  & ul li {
    text-align: start; /* Align text to the start */
  }
`;

export default MyComponent;