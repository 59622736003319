import React, { useState } from "react";
import styled from "styled-components";
import { smartcontractAddress, etherscanurl } from '../../url';
import Tooltip from './Tooltip';

const CryptoAddressContainer = styled.div`
  cursor: pointer;
  display: flex;
  width: 90%;
  justify-content: center;
  @media (max-width: 1028px) {
    padding: 0px;
    width: 90%;
  }
  @media (max-width: 991px) {
    width: 100%;
  }
  @media (max-width: 468px) {
  width: 100%;
  }
`;

const CryptoAddressText = styled.div`
font: 17px Istok Web, sans-serif;
  // flex-grow: 1;
//   flex-basis: auto;
  margin: auto 0;

  @media (max-width: 991px) {
    max-width: 100%;
  }
  @media (max-width: 468px) {
   font-size: 12px
    }
`;

const CryptoAddressIcon = styled.img`
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 24px;
  @media (max-width: 468px) {
    width: 12px;
  }

`;

const Image = styled.img`
  width: 100%;
  height: auto;
`;

function CryptoAddress() {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyAddressClick = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      setIsCopied(true);
      // setTimeout(() => setIsCopied(false), 2000); // Hide tooltip after 2 seconds
    } catch (error) {
      console.error('Failed to copy:', error);
    }
  };

  const handleAddressClick = (url) => {
    window.open(url, '_blank'); // Open the URL in a new tab
  };
  return (
    <div style={{ position: 'relative' }}>
      {isCopied && <Tooltip>Copied!</Tooltip>}
      <CryptoAddressContainer onClick={() => handleAddressClick(etherscanurl)}>
        {/* <CryptoAddressText > */}
        <Image src="/img/smartcontract.png" />
        {/* <span style={{ color: "rgba(0,212,253,1)" }}>$hWIF :</span>{" "}
          {smartcontractAddress}
        </CryptoAddressText>
        <CryptoAddressIcon style={{ zIndex: 99999 }} onClick={() => handleCopyAddressClick(smartcontractAddress)} loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/30588826f67636c4b9ffbfe1c5fd5f19f7d531c064a16ab4dee22eb4963d8703?apiKey=4ea074c2172a4f308a4b8ec9272610d2&" alt="Crypto Address Icon" /> */}
      </CryptoAddressContainer>
    </div>
  );
}

export default CryptoAddress;